<template>
    <div>
        <el-divider></el-divider>
        <el-tabs v-model="activeName">
            <el-tab-pane :label="$t('lang.温差报警')" name="first">
                <el-button-group>
                    <el-button type="primary" @click="add1">{{ $t("lang.增加") }}</el-button>
                    <el-button type="info" @click="edit1">{{ $t("lang.编辑") }}</el-button>
                    <el-button type="danger" @click="del1">{{ $t("lang.删除") }}</el-button>
                </el-button-group>
                <ve-table :columns="columns1" ref="table1" borderY style="margin-top:10px;" :table-data="tableData1" row-key-field-name="id" :cell-style-option="cellStyleOption1"></ve-table>
                <ve-pagination :total="searchObj1.total" :pageIndex="searchObj1.pageIndex" :layout="['total', 'prev', 'next', 'jumper']" style="margin-top:10px;" @on-page-number-change="load1"></ve-pagination>
            </el-tab-pane>
            <el-tab-pane :label="$t('lang.上下限报警')" name="second">
                <el-button-group>
                    <el-button type="primary" @click="add2">{{ $t("lang.增加") }}</el-button>
                    <el-button type="info" @click="edit2">{{ $t("lang.编辑") }}</el-button>
                    <el-button type="danger" @click="del2">{{ $t("lang.删除") }}</el-button>
                </el-button-group>
                <ve-table :columns="columns2" ref="table2" borderY style="margin-top:10px;" :table-data="tableData2" row-key-field-name="id" :cell-style-option="cellStyleOption2"></ve-table>
                <ve-pagination :total="searchObj2.total" :pageIndex="searchObj2.pageIndex" :layout="['total', 'prev', 'next', 'jumper']" style="margin-top:10px;" @on-page-number-change="load2"></ve-pagination>
            </el-tab-pane>
            <el-tab-pane :label="$t('lang.降温速率报警与最大温度')" name="third">
                <el-card v-loading="loading3">
                    <el-row type="flex" justify="space-between">
                        <el-col :span="11">
                            <el-row type="flex" justify="space-between" align="middle">{{ $t("lang.选择设备") }}<el-select v-model="model3.serialNumber" @change="load3" style="width:250px;">
                                    <el-option v-for="(item, index) in myCompanyDevice" :key="index" :label="item.serialNumber + '(' + item.remark + ')'" :value="item.serialNumber">
                                    </el-option>
                                </el-select>
                            </el-row>
                            <van-divider :style="{ borderColor: '#DCDFE6', margin: '10px 0px' }"></van-divider>
                            <el-row type="flex" justify="space-between" align="middle">{{ $t("lang.每天降温") }}<el-input v-model.number="model3.meiTianJiangWen" @change="save3" style="width:250px;" type="number"><template #append>℃</template></el-input>
                            </el-row>
                            <van-divider :style="{ borderColor: '#DCDFE6', margin: '10px 0px' }"></van-divider>
                            <el-row type="flex" justify="space-between" align="middle">{{ $t("lang.每四小时降温") }}<el-input v-model.number="model3.meiSiXiaoShiJiangWen" @change="save3" style="width:250px;" type="number"><template #append>℃</template></el-input>
                            </el-row>
                            <van-divider :style="{ borderColor: '#DCDFE6', margin: '10px 0px' }"></van-divider>
                        </el-col>
                        <el-col :span="11">
                            <el-row type="flex" justify="space-between" align="middle">{{ $t("lang.T1最大温度") }}<el-input v-model.number="model3.t1Max" @change="save3" style="width:250px;" type="number"><template #append>℃</template></el-input>
                            </el-row>
                            <van-divider :style="{ borderColor: '#DCDFE6', margin: '10px 0px' }"></van-divider>
                            <el-row type="flex" justify="space-between" align="middle">{{ $t("lang.T2最大温度") }}<el-input v-model.number="model3.t2Max" @change="save3" style="width:250px;" type="number"><template #append>℃</template></el-input>
                            </el-row>
                            <van-divider :style="{ borderColor: '#DCDFE6', margin: '10px 0px' }"></van-divider>
                            <el-row type="flex" justify="space-between" align="middle">{{ $t("lang.T3最大温度") }}<el-input v-model.number="model3.t3Max" @change="save3" style="width:250px;" type="number"><template #append>℃</template></el-input>
                            </el-row>
                            <van-divider :style="{ borderColor: '#DCDFE6', margin: '10px 0px' }"></van-divider>
                            <el-row type="flex" justify="space-between" align="middle">{{ $t("lang.T4最大温度") }}<el-input v-model.number="model3.t4Max" @change="save3" style="width:250px;" type="number"><template #append>℃</template></el-input>
                            </el-row>
                            <van-divider :style="{ borderColor: '#DCDFE6', margin: '10px 0px' }"></van-divider>
                            <el-row type="flex" justify="space-between" align="middle">{{ $t("lang.T5最大温度") }}<el-input v-model.number="model3.t5Max" @change="save3" style="width:250px;" type="number"><template #append>℃</template></el-input>
                            </el-row>
                            <van-divider :style="{ borderColor: '#DCDFE6', margin: '10px 0px' }"></van-divider>
                            <el-row type="flex" justify="space-between" align="middle">{{ $t("lang.T6最大温度") }}<el-input v-model.number="model3.t6Max" @change="save3" style="width:250px;" type="number"><template #append>℃</template></el-input>
                            </el-row>
                            <van-divider :style="{ borderColor: '#DCDFE6', margin: '10px 0px' }"></van-divider>
                            <el-row type="flex" justify="space-between" align="middle">{{ $t("lang.T7最大温度") }}<el-input v-model.number="model3.t7Max" @change="save3" style="width:250px;" type="number"><template #append>℃</template></el-input>
                            </el-row>
                            <van-divider :style="{ borderColor: '#DCDFE6', margin: '10px 0px' }"></van-divider>
                            <el-row type="flex" justify="space-between" align="middle">{{ $t("lang.T8最大温度") }}<el-input v-model.number="model3.t8Max" @change="save3" style="width:250px;" type="number"><template #append>℃</template></el-input>
                            </el-row>
                            <van-divider :style="{ borderColor: '#DCDFE6', margin: '10px 0px' }"></van-divider>
                        </el-col>
                    </el-row>
                </el-card>
            </el-tab-pane>
            <el-tab-pane :label="$t('lang.水泵绑定')" name="fourth">
                <el-button-group>
                    <el-button type="primary" @click="add4">{{ $t("lang.增加") }}</el-button>
                    <el-button type="info" @click="edit4">{{ $t("lang.编辑") }}</el-button>
                    <el-button type="danger" @click="del4">{{ $t("lang.删除") }}</el-button>
                </el-button-group>
                <ve-table :columns="columns4" ref="table4" borderY style="margin-top:10px;" :table-data="tableData4" row-key-field-name="id" :cell-style-option="cellStyleOption4"></ve-table>
                <ve-pagination :total="searchObj4.total" :pageIndex="searchObj4.pageIndex" :layout="['total', 'prev', 'next', 'jumper']" style="margin-top:10px;" @on-page-number-change="load4"></ve-pagination>
            </el-tab-pane>
        </el-tabs>
        <el-dialog :visible.sync="visible1" :close-on-click-modal="false" width="40%" :show-close="false">
            <template #title><i class="iconfont icon-changtiao-copy" style="color:#409EFF"></i>{{ $t("lang.温差报警") }}</template>
            <el-form :model="model1" ref="form1" label-width="150px" :rules="rules1">
                <el-form-item :label="$t('lang.温差名称')" prop="name">
                    <el-input v-model="model1.name"></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.工程名称')" prop="projectId">
                    <el-select v-model="model1.projectId" style="width:100%">
                        <el-option v-for="(item, index) in myProjectList" :key="index" :label="item.projectName" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('lang.报警间隔')" prop="baoJingJianGe">
                    <el-input v-model.number="model1.baoJingJianGe"><template #append>分钟</template></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.通道1')" required>
                    <el-col :span="16">
                        <el-form-item prop="tongDao1SerialNumber">
                            <el-select v-model="model1.tongDao1SerialNumber" style="width:100%" @change="tab1HandleChange1">
                                <el-option v-for="(item, index) in myCompanyDevice" :key="index" :label="item.serialNumber + '(' + item.remark + ')'" :value="item.serialNumber">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="tongDao1Passageway">
                            <el-select v-model="model1.tongDao1Passageway">
                                <el-option v-for="(item, index) in tab1TongDao1Count" :key="index" :value="'T' + (index + 1)"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('lang.通道2')" required>
                    <el-col :span="16">
                        <el-form-item prop="tongDao2SerialNumber">
                            <el-select v-model="model1.tongDao2SerialNumber" style="width:100%" @change="tab1HandleChange2">
                                <el-option v-for="(item, index) in myCompanyDevice" :key="index" :label="item.serialNumber + '(' + item.remark + ')'" :value="item.serialNumber">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="tongDao2Passageway">
                            <el-select v-model="model1.tongDao2Passageway">
                                <el-option v-for="(item, index) in tab1TongDao2Count" :key="index" :value="'T' + (index + 1)"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form-item>

                <el-form-item :label="$t('lang.差值')" prop="chaZhi">
                    <el-input v-model.number="model1.chaZhi" type="number"><template #append>℃</template></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.备注')">
                    <el-input v-model="model1.remark"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div style="display:flex;flex-direction:row-reverse">
                    <el-button type="primary" style="margin-left:10px;" @click="submit1">{{ $t("lang.保存") }}</el-button>
                    <el-button type="info" @click="visible1 = false">{{ $t("lang.取消") }}</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :visible.sync="visible2" :close-on-click-modal="false" width="40%" :show-close="false">
            <template #title><i class="iconfont icon-changtiao-copy" style="color:#409EFF"></i>{{ $t("lang.上下限报警") }}</template>
            <el-form :model="model2" ref="form2" label-width="150px" :rules="rules2">
                <el-form-item :label="$t('lang.上下限名称')" prop="name">
                    <el-input v-model="model2.name"></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.工程名称')" prop="projectId">
                    <el-select v-model="model2.projectId" style="width:100%">
                        <el-option v-for="(item, index) in myProjectList" :key="index" :label="item.projectName" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('lang.报警间隔')" prop="baoJingJianGe">
                    <el-input v-model.number="model2.baoJingJianGe"><template #append>{{ $t("lang.分钟") }}</template></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.通道')" required>
                    <el-col :span="16">
                        <el-form-item prop="tongDaoSerialNumber">
                            <el-select v-model="model2.tongDaoSerialNumber" style="width:100%" @change="tab2HandleChange">
                                <el-option v-for="(item, index) in myCompanyDevice" :key="index" :label="item.serialNumber + '(' + item.remark + ')'" :value="item.serialNumber">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="tongDaoPassageway">
                            <el-select v-model="model2.tongDaoPassageway">
                                <el-option v-for="(item, index) in tab2TongDaoCount" :key="index" :value="'T' + (index + 1)"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('lang.上限值')" prop="shangXian">
                    <el-input v-model.number="model2.shangXian" type="number"><template #append>℃</template></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.下限值')" prop="xiaXian">
                    <el-input v-model.number="model2.xiaXian" type="number"><template #append>℃</template></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.备注')">
                    <el-input v-model="model2.remark"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div style="display:flex;flex-direction:row-reverse">
                    <el-button type="primary" style="margin-left:10px;" @click="submit2">{{ $t("lang.保存") }}</el-button>
                    <el-button type="info" @click="visible2 = false">{{ $t("lang.取消") }}</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :visible.sync="visible4" :close-on-click-modal="false" width="40%" :show-close="false">
            <template #title><i class="iconfont icon-changtiao-copy" style="color:#409EFF"></i>{{ $t("lang.水泵绑定") }}</template>
            <el-form :model="model4" ref="form4" label-width="150px" :rules="rules4">
                <el-form-item :label="$t('lang.名称')" prop="name">
                    <el-input v-model="model4.name"></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.通道')" required>
                    <el-col :span="16">
                        <el-form-item prop="serialNumber">
                            <el-select v-model="model4.serialNumber" style="width:100%" @change="tab4HandleChange">
                                <el-option v-for="(item, index) in myCompanyDevice" :key="index" :label="item.serialNumber + '(' + item.remark + ')'" :value="item.serialNumber">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="passageway">
                            <el-select v-model="model4.passageway">
                                <el-option v-for="(item, index) in tab4TongDaoCount" :key="index" :value="'T' + (index + 1)"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('lang.温度')" prop="temperature">
                    <el-input v-model.number="model4.temperature" type="number"><template #append>℃</template></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.开关')" required>
                    <el-col :span="16">
                        <el-form-item prop="imei">
                            <el-input v-model="model4.imei" :placeholder="$t('lang.请输入开关imei')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="channel">
                            <el-select v-model="model4.channel">
                                <el-option value="1" :label="$t('lang.继电器1')"></el-option>
                                <el-option value="2" :label="$t('lang.继电器2')"></el-option>
                                <el-option value="3" :label="$t('lang.继电器3')"></el-option>
                                <el-option value="4" :label="$t('lang.继电器4')"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('lang.备注')">
                    <el-input v-model="model4.remark"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div style="display:flex;flex-direction:row-reverse">
                    <el-button type="primary" style="margin-left:10px;" @click="submit4">{{ $t("lang.保存") }}</el-button>
                    <el-button type="info" @click="visible4 = false">{{ $t("lang.取消") }}</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :visible.sync="visible4_detail" :close-on-click-modal="false" width="25%" :show-close="false">
            <template #title><i class="iconfont icon-changtiao-copy" style="color:#409EFF"></i>{{ $t("lang.开关明细") }}</template>
            <ve-table :columns="columns4_detail" :table-data="tableData4_detail" maxHeight="500px" borderY />
            <template #footer>
                <div style="display:flex;flex-direction:row-reverse">
                    <el-button type="info" @click="visible4_detail = false">{{ $t("lang.关闭") }}</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import Vue from "vue";

// 引入样式
import "vue-easytable/libs/theme-default/index.css";
// 引入组件库
import VueEasytable from "vue-easytable";

import { Divider } from "vant";
Vue.use(Divider);

Vue.use(VueEasytable);

export default {
    data() {
        let that = this;
        return {
            myProjectList: [],
            myCompanyDevice: [],
            activeName: "first",
            tab1TongDao1Count: 8,
            tab1TongDao2Count: 8,
            visible1: false,
            searchObj1: {
                pageIndex: 1,
                pageSize: 10,
                total: 0,
            },
            tableData1: [],
            model1: {},
            cellStyleOption1: {
                bodyCellClass: ({ column }) => {
                    if (column.field == "tongDao1" || column.field == "tongDao2" || column.field == "chaZhi") {
                        return "bgRed-WXCW03BJGL";
                    }
                },
            },
            rules1: {
                name: [{ required: true, message: that.$t("lang.请输入温差名称"), trigger: "change" }],
                projectId: [{ required: true, message: that.$t("lang.请选择工程名称"), trigger: "change" }],
                baoJingJianGe: [
                    { required: true, message: that.$t("lang.请输入报警间隔"), trigger: "change" },
                    { type: "number", message: that.$t("lang.报警间隔必须为数字值") },
                ],
                tongDao1SerialNumber: [{ required: true, message: that.$t("lang.请选择设备"), trigger: "change" }],
                tongDao1Passageway: [{ required: true, message: that.$t("lang.请选择设备"), trigger: "change" }],
                tongDao2SerialNumber: [{ required: true, message: that.$t("lang.请选择设备"), trigger: "change" }],
                tongDao2Passageway: [{ required: true, message: that.$t("lang.请选择设备"), trigger: "change" }],
                chaZhi: [{ required: true, message: that.$t("lang.请输入差值"), trigger: "change" }],
            },
            columns1: [
                { field: "name", key: "name", title: that.$t("lang.温差名称") },
                { field: "projectName", key: "projectName", title: that.$t("lang.工程名称") },
                { field: "baoJingJianGe", key: "baoJingJianGe", title: that.$t("lang.报警间隔") },
                {
                    field: "tongDao1",
                    key: "tongDao1",
                    title: that.$t("lang.通道1"),
                    renderBodyCell: ({ row }) => {
                        let tmp = row.tongDao1SerialNumber + "(" + row.tongDao1Passageway + ")";
                        let tmp1 = row.cD_Name1;
                        return <span>{tmp}{tmp1 ? "----" + tmp1 : ""}</span>;
                    },
                },
                {
                    field: "tongDao2",
                    key: "tongDao2",
                    title: that.$t("lang.通道2"),
                    renderBodyCell: ({ row }) => {
                        let tmp = row.tongDao2SerialNumber + "(" + row.tongDao2Passageway + ")";
                        let tmp1 = row.cD_Name2;
                        return <span>{tmp}{tmp1 ? "----" + tmp1 : ""}</span>;
                    },
                },
                { field: "chaZhi", key: "chaZhi", title: that.$t("lang.差值") },
                { field: "remark", key: "remark", title: that.$t("lang.备注") },
                { field: "createTime", key: "createTime", title: that.$t("lang.创建时间") },
            ],
            visible2: false,
            searchObj2: {
                pageIndex: 1,
                pageSize: 10,
                total: 0,
            },
            tab2TongDaoCount: 8,
            tableData2: [],
            model2: {},
            cellStyleOption2: {
                bodyCellClass: ({ column }) => {
                    if (column.field == "tongDao" || column.field == "shangXian" || column.field == "xiaXian") {
                        return "bgRed-WXCW03BJGL";
                    }
                },
            },
            rules2: {
                name: [{ required: true, message: that.$t("lang.请输入上下限名称"), trigger: "change" }],
                projectId: [{ required: true, message: that.$t("lang.请选择工程名称"), trigger: "change" }],
                baoJingJianGe: [
                    { required: true, message: that.$t("lang.请输入报警间隔"), trigger: "change" },
                    { type: "number", message: that.$t("lang.报警间隔必须为数字值") },
                ],
                tongDaoSerialNumber: [{ required: true, message: that.$t("lang.请选择设备"), trigger: "change" }],
                tongDaoPassageway: [{ required: true, message: that.$t("lang.请选择设备"), trigger: "change" }],
                shangXian: [{ required: true, message: that.$t("lang.请输入差值"), trigger: "change" }],
                xiaXian: [{ required: true, message: that.$t("lang.请输入差值"), trigger: "change" }],
            },
            columns2: [
                { field: "name", key: "name", title: that.$t("lang.上下限名称") },
                { field: "projectName", key: "projectName", title: that.$t("lang.工程名称") },
                { field: "baoJingJianGe", key: "baoJingJianGe", title: that.$t("lang.报警间隔") },
                {
                    field: "tongDao",
                    key: "tongDao",
                    title: that.$t("lang.通道"),
                    renderBodyCell: ({ row }) => {
                        let tmp = row.tongDaoSerialNumber + "(" + row.tongDaoPassageway + ")";
                        let tmp1 = row.cD_Name;
                        return <span>{tmp}{tmp1 ? "----" + tmp1 : ""}</span>;
                    },
                },
                { field: "shangXian", key: "shangXian", title: that.$t("lang.上限值") },
                { field: "xiaXian", key: "xiaXian", title: that.$t("lang.下限值") },
                { field: "remark", key: "remark", title: that.$t("lang.备注") },
                { field: "createTime", key: "createTime", title: that.$t("lang.创建时间") },
            ],
            model3: {},
            loading3: false,
            tab4TongDaoCount: 8,
            visible4: false,
            searchObj4: {
                pageIndex: 1,
                pageSize: 10,
                total: 0,
            },
            tableData4: [],
            model4: {},
            cellStyleOption4: {
                bodyCellClass: ({ column }) => {
                    if (column.field == "tongDao") {
                        return "bgRed-WXCW03BJGL";
                    }
                    else if (column.field == "temperature") {
                        return "bgRed1-WXCW03BJGL";
                    }
                    else if (column.field == "imei" || column.field == "channel") {
                        return "bgBlue-WXCW03BJGL";
                    }
                },
            },
            rules4: {
                name: [{ required: true, message: that.$t("lang.请输入名称"), trigger: "change" }],
                serialNumber: [{ required: true, message: that.$t("lang.请选择设备"), trigger: "change" }],
                passageway: [{ required: true, message: that.$t("lang.请选择通道"), trigger: "change" }],
                temperature: [
                    { required: true, message: that.$t("lang.请输入报警间隔"), trigger: "change" },
                    { type: "number", message: that.$t("lang.报警间隔必须为数字值") },
                ],
                imei: [{ required: true, message: that.$t("lang.请输入开关imei"), trigger: "change" },
                { min: 15, max: 15, message: that.$t("lang.长度15个字符"), trigger: 'blur' }],
                channel: [{ required: true, message: that.$t("lang.请选择继电器"), trigger: "change" }],
            },
            columns4: [
                { field: "name", key: "name", title: that.$t("lang.名称") },
                {
                    field: "tongDao",
                    key: "tongDao",
                    title: that.$t("lang.通道"),
                    renderBodyCell: ({ row }) => {
                        return row.serialNumber + "(" + row.passageway + ")";
                    },
                },
                { field: "temperature", key: "temperature", title: that.$t("lang.温度") },
                { field: "imei", key: "imei", title: that.$t("lang.开关imei") },
                { field: "channel", key: "channel", title: that.$t("lang.继电器") },
                { field: "remark", key: "remark", title: that.$t("lang.备注") },
                { field: "createTime", key: "createTime", title: that.$t("lang.创建时间") },
                {
                    field: "",
                    key: "e",
                    title: that.$t("lang.操作"),
                    renderBodyCell: ({ row }) => {
                        return (
                            <el-button type="primary" size="mini" on-click={() => that.loadDetail4(row)}>{that.$t('lang.明细')}</el-button>
                        );
                    },
                },
            ],
            visible4_detail: false,
            columns4_detail: [
                { field: "createTime", key: "createTime", title: that.$t("lang.时间") },
                { field: "msg", key: "msg", title: "msg" },],
            tableData4_detail: [],

        };
    },
    methods: {
        tab1HandleChange1(v) {
            let that = this;
            let selectSerialNumberObject = that.myCompanyDevice.find((ele) => { return ele.serialNumber == v; });
            if (selectSerialNumberObject.deviceType == "WXCW80") {
                that.tab1TongDao1Count = 4;
            } else {
                that.tab1TongDao1Count = 8;
            }
        },
        tab1HandleChange2(v) {
            let that = this;
            let selectSerialNumberObject = that.myCompanyDevice.find((ele) => { return ele.serialNumber == v; });
            if (selectSerialNumberObject.deviceType == "WXCW80") {
                that.tab1TongDao2Count = 4;
            } else {
                that.tab1TongDao2Count = 8;
            }
        },
        add1() {
            let that = this;
            that.visible1 = true;
            that.model1 = { baoJingJianGe: 30 };
            if (that.$refs["form1"]) {
                that.$refs["form1"].resetFields();
            }
        },
        edit1() {
            let that = this;
            if (that.$refs["table1"].highlightRowKey) {
                that.axios
                    .post("WXCW03/GetOneWCBJ", {
                        onlyInt: that.$refs["table1"].highlightRowKey,
                    })
                    .then((response) => {
                        that.model1 = response.data.data;
                        that.visible1 = true;
                        //初始化编辑页面的通道数量
                        let tmpObject = that.myCompanyDevice.find((ele) => { return ele.serialNumber == that.model1.tongDao1SerialNumber; });
                        if (tmpObject.deviceType == "WXCW80") {
                            that.tab1TongDao1Count = 4;
                        }
                        else {
                            that.tab1TongDao1Count = 8;
                        }
                        tmpObject = that.myCompanyDevice.find((ele) => { return ele.serialNumber == that.model1.tongDao2SerialNumber; });
                        if (tmpObject.deviceType == "WXCW80") {
                            that.tab1TongDao2Count = 4;
                        }
                        else {
                            that.tab1TongDao2Count = 8;
                        }
                    });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择一条记录") });
            }
        },
        del1() {
            let that = this;
            if (that.$refs["table1"].highlightRowKey) {
                that.$confirm(that.$t("lang.确定要删除吗"))
                    .then(function () {
                        that.axios
                            .post("WXCW03/DelWCBJ", {
                                onlyInt: that.$refs["table1"].highlightRowKey,
                            })
                            .then(function () {
                                that.$refs["table1"].highlightRowKey = "";
                                that.$message({ type: "success", message: that.$t("lang.删除成功") });
                                that.load1();
                            });
                    })
                    .catch(() => { });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择一条记录") });
            }
        },
        submit1() {
            let that = this;
            that.$refs["form1"].validate((valid) => {
                if (valid) {
                    that.axios.post("WXCW03/SaveWCBJ", that.model1).then((response) => {
                        if (response.data.code == 101) {
                            that.visible1 = false;
                            that.$message({ type: "success", message: that.$t("lang.保存成功") });
                            that.load1();
                        }
                    });
                }
            });
        },
        load1(v) {
            let that = this;
            if (v) {
                that.searchObj1.pageIndex = v;
            }
            that.axios.post("WXCW03/GetWCBJ", that.searchObj1).then((response) => {
                that.tableData1 = response.data.data.data;
                that.searchObj1.total = response.data.data.total;
            });
        },
        tab2HandleChange(v) {
            let that = this;
            let selectSerialNumberObject = that.myCompanyDevice.find((ele) => { return ele.serialNumber == v; });
            if (selectSerialNumberObject.deviceType == "WXCW80") {
                that.tab2TongDaoCount = 4;
            } else {
                that.tab2TongDaoCount = 8;
            }
        },
        add2() {
            let that = this;
            that.visible2 = true;
            that.model2 = { baoJingJianGe: 30 };
            if (that.$refs["form2"]) {
                that.$refs["form2"].resetFields();
            }
        },
        edit2() {
            let that = this;
            if (that.$refs["table2"].highlightRowKey) {
                that.axios
                    .post("WXCW03/GetOneSXXBJ", {
                        onlyInt: that.$refs["table2"].highlightRowKey,
                    })
                    .then((response) => {
                        that.model2 = response.data.data;
                        that.visible2 = true;
                        //初始化编辑页面的通道数量
                        let tmpObject = that.myCompanyDevice.find((ele) => { return ele.serialNumber == that.model2.tongDaoSerialNumber; });
                        if (tmpObject.deviceType == "WXCW80") {
                            that.tab2TongDaoCount = 4;
                        }
                        else {
                            that.tab2TongDaoCount = 8;
                        }
                    });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择一条记录") });
            }
        },
        del2() {
            let that = this;
            if (that.$refs["table2"].highlightRowKey) {
                that.$confirm(that.$t("lang.确定要删除吗"))
                    .then(function () {
                        that.axios
                            .post("WXCW03/DelSXXBJ", {
                                onlyInt: that.$refs["table2"].highlightRowKey,
                            })
                            .then(function () {
                                that.$refs["table2"].highlightRowKey = "";
                                that.$message({ type: "success", message: that.$t("lang.删除成功") });
                                that.load2();
                            });
                    })
                    .catch(() => { });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择一条记录") });
            }
        },
        submit2() {
            let that = this;
            that.$refs["form2"].validate((valid) => {
                if (valid) {
                    that.axios.post("WXCW03/SaveSXXBJ", that.model2).then((response) => {
                        if (response.data.code == 101) {
                            that.visible2 = false;
                            that.$message({ type: "success", message: that.$t("lang.保存成功") });
                            that.load2();
                        }
                    });
                }
            });
        },
        load2(v) {
            let that = this;
            if (v) {
                that.searchObj2.pageIndex = v;
            }
            that.axios.post("WXCW03/GetSXXBJ", that.searchObj2).then((response) => {
                that.tableData2 = response.data.data.data;
                that.searchObj2.total = response.data.data.total;
            });
        },
        load3() {
            let that = this;
            if (that.model3.serialNumber) {
                that.loading3 = true;
                that.axios
                    .post("WXCW03/GetDeviceConfig", {
                        onlyText: that.model3.serialNumber,
                    })
                    .then((response) => {
                        that.loading3 = false;
                        that.model3 = response.data.data;
                    });
            }
        },
        save3() {
            let that = this;
            if (that.model3.serialNumber) {
                that.loading3 = true;
                that.axios.post("WXCW03/SaveDeviceConfig", that.model3).then((response) => {
                    if (response.data.code == 101) {
                        that.loading3 = false;
                        that.$message({ type: "success", message: that.$t("lang.保存成功") });
                    }
                });
            }
        },
        tab4HandleChange(v) {
            let that = this;
            let selectSerialNumberObject = that.myCompanyDevice.find((ele) => { return ele.serialNumber == v; });
            if (selectSerialNumberObject.deviceType == "WXCW80") {
                that.tab4TongDaoCount = 4;
            } else {
                that.tab4TongDaoCount = 8;
            }
        },
        add4() {
            let that = this;
            that.visible4 = true;
            that.model4 = { baoJingJianGe: 30 };
            if (that.$refs["form4"]) {
                that.$refs["form4"].resetFields();
            }
        },
        edit4() {
            let that = this;
            if (that.$refs["table4"].highlightRowKey) {
                that.axios
                    .post("YinErDa/Get_One_WXCW03_ShuiBeng", {
                        onlyInt: that.$refs["table4"].highlightRowKey,
                    })
                    .then((response) => {
                        that.model4 = response.data.data;
                        that.visible4 = true;
                        let tmpObject = that.myCompanyDevice.find((ele) => { return ele.serialNumber == that.model4.serialNumber; });
                        if (tmpObject.deviceType == "WXCW80") {
                            that.tab4TongDaoCount = 4;
                        }
                        else {
                            that.tab4TongDaoCount = 8;
                        }
                    });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择一条记录") });
            }
        },
        del4() {
            let that = this;
            if (that.$refs["table4"].highlightRowKey) {
                that.$confirm(that.$t("lang.确定要删除吗"))
                    .then(function () {
                        that.axios
                            .post("YinErDa/Del_WXCW03_ShuiBeng", {
                                onlyInt: that.$refs["table4"].highlightRowKey,
                            })
                            .then(function () {
                                that.$refs["table4"].highlightRowKey = "";
                                that.$message({ type: "success", message: that.$t("lang.删除成功") });
                                that.load4();
                            });
                    })
                    .catch(() => { });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择一条记录") });
            }
        },
        submit4() {
            let that = this;
            that.$refs["form4"].validate((valid) => {
                if (valid) {
                    that.axios.post("YinErDa/Save_WXCW03_ShuiBeng", that.model4).then((response) => {
                        if (response.data.code == 101) {
                            that.visible4 = false;
                            that.$message({ type: "success", message: that.$t("lang.保存成功") });
                            that.load4();
                        }
                    });
                }
            });
        },
        load4(v) {
            let that = this;
            if (v) {
                that.searchObj4.pageIndex = v;
            }
            that.axios.post("YinErDa/Get_WXCW03_ShuiBeng", that.searchObj4).then((response) => {
                that.tableData4 = response.data.data.data;
                that.searchObj4.total = response.data.data.total;
            });
        },
        loadDetail4(row) {
            let that = this;
            that.visible4_detail = true;
            that.axios.post("YinErDa/Get_WXCW03_ShuiBeng_Log", { onlyInt: row.id }).then((response) => {
                that.tableData4_detail = response.data.data;
            });
        },
        loadMyCompanyDevice() {
            let that = this;
            that.axios
                .post("WXCW03/GetMyCompanyDevice1", {
                    deviceType: "WXCW", //这里以后改成WXCW03
                })
                .then((response) => {
                    that.myCompanyDevice = response.data.data;
                });
        },
        loadMyProject() {
            let that = this;
            that.axios.post("WXCW03/GetMyProject", { pageSize: 1000 }).then((response) => {
                that.myProjectList = response.data.data.data;
            });
        },
    },
    mounted() {
        let that = this;
        that.load1();
        that.load2();
        that.load4();
        that.loadMyCompanyDevice();
        that.loadMyProject();
    },
};
</script>

<style>
.bgRed-WXCW03BJGL {
    background-color: #e6a23c !important;
}

.bgBlue-WXCW03BJGL {
    background-color: #409EFF !important;
}

.bgRed1-WXCW03BJGL {
    background-color: #F56C6C !important;
}
</style>